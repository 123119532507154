import { customAxios as axios } from '@/plugins/axios';

import { useAuthStore } from './auth';
import { useErrorStore } from './error';

import type { City, Pagination } from '~/types/types';

export const useCityStore = defineStore('city', {
    state: () => {
        return {
            loading: false,
            params: {
                page: 1,
                pageSize: 25,
                search: null
            },
            city: {} as City,
            cities: [] as City[],
            citiesPagination: {} as Pagination,
            confirmed: false,
        };
    },
    getters: {
        token: (): string | null => {
            const store = useAuthStore();
            const { token } = storeToRefs(store);

            return token.value.access;
        },
    },
    actions: {
        clearData() {
            this.city = {} as City;
            localStorage.removeItem('city');
            localStorage.removeItem('cityConfirmed');

            this.cities = [];
            this.citiesPagination = {} as Pagination;
        },
        async fetchCities() {
            if (this.cities.length === 0) {
                this.loading = true;
                try {
                    const params = { ...this.params };
                    const response: { data: { data: City[], pager: Pagination } } = await axios.get('/v1/cities', { params });

                    if (response.data) {
                        const { data, pager } = response.data;

                        this.cities = data;
                        this.citiesPagination = pager;
                    } else {
                        this.clearData()
                    }
                } catch (error: any) {
                    useErrorStore().setError(error, 'city');
                    console.error('fetchCities error', error)
                } finally {
                    this.loading = false;
                }
            }

            return this.cities;
        },
        async fetchCityByCoords(latitude: number, longitude: number) {
            this.loading = true;
            try {
                const params = { latitude, longitude };
                const response: { data: City } = await axios.get('/v1/cities/position', { params });

                if (response.data) {
                    const { data } = response;

                    this.saveCityToLocalStorage(data);
                    this.city = data;
                } else {
                    this.clearData()
                }
            } catch (error: any) {
                useErrorStore().setError(error, 'city');
                console.error('fetchCityByCoords error', error)
            } finally {
                this.loading = false;
            }

            return this.city;
        },
        saveCityToLocalStorage(city: City) {
            this.city = city;
            localStorage.setItem('city', JSON.stringify(city));
        },
        setConfirmedCityToLocalStorage(status: boolean) {
            this.confirmed = status;
            localStorage.setItem("cityConfirmed", String(status));
        },
        async loadCityFromLocalStorage() {
            const savedCity = localStorage.getItem('city');
            if (savedCity) {
                try {
                    this.city = JSON.parse(savedCity);
                } catch (error) {
                    console.error('Error parsing city data:', error);
                }
            }

            const savedConfirmedCity = localStorage.getItem("cityConfirmed")
            if (savedConfirmedCity) {
                try {
                    this.confirmed = JSON.parse(savedConfirmedCity);
                } catch (error) {
                    console.error('Error parsing confirmed city data:', error);
                }
            }
        },
    }
});
