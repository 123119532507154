export function useDeclineCity() {
    const declineCity = (city: string): string => {
        const declensions: { [key: string]: string } = {
            "а": "е",
            "ия": "ии",
            "ль": "ле",
            "дар": "даре",
            "ым": "ыму"
        };
        const lowercaseCity = city.toLowerCase();

        for (const ending in declensions) {
            if (lowercaseCity.endsWith(ending)) {
                return city.slice(0, -ending.length) + declensions[ending];
            }
        }

        return city;
    };


    return {
        declineCity
    };
}