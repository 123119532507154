import { useModalStore } from "./modal";

export const useErrorStore = defineStore('error', {
    state: () => {
        return {
            error: {
                code: 0,
                event: '',
                message: '',
                status: 0,
            }
        };
    },
    actions: {
        setError(error: { response: { data: { message: string; code: number; statusCode: number; }; status: number; statusText: string; }; message: string; }, event?: string) {
            const { openModal } = useModalStore()

            openModal('error')

            this.error = {
                code: error.response?.data?.code || error?.response?.status || 0,
                event: event || '',
                message: error.response?.data?.message || error?.response?.statusText || error?.message || '',
                status: error.response?.data?.statusCode || error.response?.status || 0,
            }
            console.error("Error:", error);
        },
        setCustomError(message: string, code: number = 400) {
            const error = {
              response: {
                data: {
                  code,
                  message,
                  statusCode: code,
                },
                status: code,
                statusText: "Custom error",
              },
              message,
            };
          
            this.setError(error);
        },
        clearError() {
            const { closeModal } = useModalStore()
            closeModal('error')

            this.error = {
                code: 0,
                event: '',
                message: '',
                status: 0
            }
        }
    }
});
