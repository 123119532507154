import { customAxios as axios } from '@/plugins/axios';
import type { AuthBody, AuthResponse } from '~/types/types';

import { useErrorStore } from './error';
import { useProfileStore } from './profile';
import { useAdminStore } from './admin';

export const useAuthStore = defineStore('auth', {
    state: () => {
        return {
            body: {
                phoneNumber: '',
                email: '',
                code: 0
            } as AuthBody,
            // expires: null as string | null,
            token: {
                access: null as string | null,
                refresh: null as string | null
            },
            // state flags
            loading: false,
            isRefreshingTokens: false,
        };
    },
    getters: {
        isAuthorized: (state): boolean => {
            return !!(state.token.access && state.token.refresh)
        }
    },
    actions: {
        clearData() {
            const { clearAllAdminState } = useAdminStore();
            const { clearAddresses, clearUser, clearBody, clearOrderCount, clearOrdersData, saveAddressToLocalStorage } = useProfileStore();

            this.clearToken()
            // profile data
            clearAddresses();
            clearUser();
            clearBody();
            clearOrderCount();
            clearOrdersData();
            saveAddressToLocalStorage();
            // admin data
            clearAllAdminState()
        },
        updateBody(data: Partial<AuthBody>) {
            Object.assign(this.body, data);
        },
        async sendCode() {
            this.loading = true;

            try {
                // let body = { phoneNumber: this.body.phoneNumber }
                let body = { email: this.body.email }
                const { data }: { data: number } = await axios.post('/api/auth/send-code', body);
                return data;
            }
            catch (e: any) {
                useErrorStore().setError(e)
                console.error('sendCode error', e)

                return e.response?.data?.code || false
            } finally {
                this.loading = false;
            }
        },
        async login() {
            const { fetchUser } = useProfileStore();
            this.loading = true;

            try {
                let body = { ...this.body }
                const { data }: { data: AuthResponse } = await axios.post('/api/auth/login', body);
                const { accessToken, refreshToken } = data;

                if (accessToken && refreshToken) {
                    this.setTokens({ accessToken, refreshToken })
                    fetchUser();
                }

                return data;
            }
            catch (e: any) {
                useErrorStore().setError(e, 'login')
                console.error('login error', e)

                return { code: e.response?.data?.code || false } as AuthResponse
            } finally {
                this.loading = false;
            }
        },
        async refreshTokens() {
            if (this.isRefreshingTokens) return;

            this.loading = true;
            this.isRefreshingTokens = true;

            try {
                const { data }: { data: AuthResponse } = await axios.get('/api/auth/refresh',
                    {
                        headers: {
                            'Authorization': `Bearer ${this.token.refresh}`,
                        },
                        // withCredentials: true,
                    }
                );
                const { accessToken, refreshToken } = data;

                if (accessToken && refreshToken) {
                    this.setTokens({ accessToken, refreshToken })
                }

                return data;
            }
            catch (e: any) {
                console.error('refresh error', e)

                if (e?.response?.status === 401 || e?.response?.status === 403 || e?.response?.status === 404) {
                    this.logout()
                } else {
                    useErrorStore().setError(e, 'refresh')
                }
            } finally {
                this.loading = false;
                this.isRefreshingTokens = false;
            }
        },
        async logout() {
            this.loading = true;

            try {
                await axios.get('/api/auth/logout',
                    {
                        headers: {
                            'Authorization': `Bearer ${this.token.refresh}`
                        },
                        // withCredentials: true
                    }
                );
                this.clearData()
            }
            catch (e: any) {
                console.error('logout error', e)

                if (e?.response?.status === 401 || e?.response?.status === 403 || e?.response?.status === 404) {
                    this.clearData()
                }
            } finally {
                this.loading = false;
            }
        },
        setTokens(token: AuthResponse) {
            this.token = {
                access: token.accessToken,
                refresh: token.refreshToken
            };
            localStorage.setItem('accessToken', token.accessToken)
            localStorage.setItem('refreshToken', token.refreshToken)

            // const dayjs = useDayjs()
            // this.expires = dayjs().add(10, 'hours').toISOString()
            // localStorage.setItem('expires', this.expires)
        },
        async getTokens() {
            // const dayjs = useDayjs()
            // const expires = localStorage.getItem('expires')

            const savedToken = {
                access: localStorage.getItem('accessToken'),
                refresh: localStorage.getItem('refreshToken')
            }

            if (savedToken) {
                try {
                    this.token = savedToken;

                    // если будет expires на фронте
                    // if (this.token.access && this.token.refresh && !dayjs(expires).isValid() || dayjs().isAfter(dayjs(expires))) {
                    //     await this.refresh();
                    // }
                } catch (error) {
                    console.error('Error parsing token data:', error);
                }
            }

            return this.token;
        },
        clearToken() {
            this.token = {
                access: null,
                refresh: null
            }

            // this.expires = null
            // localStorage.removeItem('expires')
            localStorage.removeItem('accessToken')
            localStorage.removeItem('refreshToken')
        }
    }
});
