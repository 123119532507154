import { useRoute, type RouteLocationRaw } from 'vue-router'

// не актуален = раньше нужно было сохранять sort при смене роута, загрузке страницы и.т.д (сейчас фильтра нет)
export function useQueryNavigation() {
    const route = useRoute()

    // генерит параметры для route ссылок, удаляя search - но оставляя другие query
    const routeParameters = (newPath?: string, newQuery?: Record<string, any>) => {
        const path = newPath || route.path;

        const query = { ...route.query };
        delete query.search;

        const routeLocation: RouteLocationRaw = ({ path, query: { ...query, ...newQuery } })
        // const routeLocation: RouteLocationRaw = ({ path, query: { ...query } })
        return routeLocation;
    }

    return {
        routeParameters
    }
}
