export const useModalStore = defineStore('modal', {
    state: () => {
        return {
            modal: {
                checkout: false,
                checkoutDetail: false,
                clearCart: false,
                error: false,
                partnerWarn: false,
                product: false,
                otp: false,
                outstock: false,
                share: false,
                signin: false,
                phone: false,
                city: false,

                // profile
                profile: false,
                order: false,
                orders: false,
                address: false,
                settings: false,
                feedback: false,

                // admin
                admin: false,
                adminOrders: false,
                adminOrder: false,
                categories: false,
                products: false,
                openProduct: false,
                editProduct: false,
            },
            mobileModal: {
                cart: false,
                catalog: false,
            }
        };
    },
    actions: {
        openModal(type: keyof typeof this.modal) {
            this.modal[type] = true;
        },
        closeModal(type: keyof typeof this.modal) {
            this.modal[type] = false;
        },
        closeAllModals(except?: string | string[]) {
            const modalKeys = Object.keys(this.modal) as (keyof typeof this.modal)[];
            for (const modalKey of modalKeys) {
                if (Array.isArray(except)) {
                    if (!except.includes(modalKey)) this.closeModal(modalKey);
                } else {
                    if (modalKey !== except) this.closeModal(modalKey);
                }
            }
        },
        openMobileModal(type: keyof typeof this.mobileModal) {
            this.mobileModal[type] = true;
        },
        closeMobileModal(type: keyof typeof this.mobileModal) {
            this.mobileModal[type] = false;
        },
        closeAllMobileModals() {
            const modalKeys = Object.keys(
                this.mobileModal
            ) as (keyof typeof this.mobileModal)[];
            for (const modalKey of modalKeys) {
                this.closeMobileModal(modalKey);
            }
        },
    }
});
