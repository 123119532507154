import { customAxios as axios } from '@/plugins/axios'
import { authorizedRequest } from '~/utils/api';
import type { Address, AddressBody, Order, OrderBody, OrderCommentBody, OrderCount, OrderInfo, OrderPagination, OrderParameters, OrderState, User, UserBody, FeedbackBody } from '~/types/types';

import { useAdminStore } from "./admin";
import { useAuthStore } from "./auth";
import { useCartStore } from "./cart";
import { useErrorStore } from './error';

export const useProfileStore = defineStore('profile', {
    state: () => {
        return {
            addresses: [] as Address[],
            body: {
                address: {} as AddressBody,
                comment: {} as OrderCommentBody,
                feedback: {} as FeedbackBody,
                order: {} as OrderBody,
                user: {} as UserBody
            },
            loading: false,
            order: {} as OrderInfo,
            orders: [] as Order[],
            ordersCount: {} as OrderCount,
            ordersPagination: {} as OrderPagination,
            savedAddress: '' as string,
            totalCount: 0,
            user: {} as User,
        }
    },
    actions: {
        clearAddresses() {
            this.addresses = [] as Address[];
        },
        clearUser() {
            this.user = {} as User;
        },
        clearBody() {
            this.body.address = {} as AddressBody;
            this.body.comment = {} as OrderCommentBody;
            this.body.feedback = {} as FeedbackBody;
            this.body.order = {} as OrderBody;
            this.body.user = {} as UserBody;
        },
        updateBody(data: Partial<AddressBody | FeedbackBody | OrderBody | OrderCommentBody | UserBody>, type: string) {
            if (type === 'address') {
                Object.assign(this.body.address, data);
            } else if (type === 'comment') {
                Object.assign(this.body.comment, data);
            } else if (type === 'feedback') {
                Object.assign(this.body.feedback, data);
            } else if (type === 'order') {
                Object.assign(this.body.order, data);
            } else if (type === 'user') {
                Object.assign(this.body.user, data);
            }
        },
        async fetchUser() {
            if (!this.user.id) {
                this.loading = true;
                try {
                    const response = await authorizedRequest(() => axios.get('/v1/user', {
                        headers: {
                            'Authorization': `Bearer ${useAuthStore().token.access}`
                        }
                    }));
                    if (response?.data) {
                        await this.fetchOrdersCount()
                        this.user = Object.assign(this.user, response.data);

                        if (response.data.type >= 50) {
                            await useAdminStore().fetchAdminOrdersCount()
                        }
                    }
                    /*
                    else {
                        console.warn('Could not perform the request due to token issues.');
                    }
                    */
                } catch (error: any) {
                    console.error('fetchUser error', error);

                    if (error?.response?.status === 404) {
                        useAuthStore().logout()
                    } else {
                        useErrorStore().setError(error, 'user');
                    }
                } finally {
                    this.loading = false;
                }
            }
            return this.user;
        },
        async updateUser() {
            this.loading = true;

            try {
                const response = await authorizedRequest(() => axios.patch('/v1/user', this.body.user, {
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().token.access}`
                    }
                }));
                if (response?.data) {
                    this.user = Object.assign(this.user, response.data);
                    return this.user;
                }
            } catch (error: any) {
                console.error('updateUser error', error);
                useErrorStore().setError(error, 'user');
            } finally {
                this.loading = false;
            }
        },
        async telegramBind() {
            this.loading = true;
            try {
                const response = await authorizedRequest(() => axios.get('/v1/user/telegram-link', {
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().token.access}`
                    }
                }));

                const { telegramLink } = response?.data;
                return telegramLink;
            } catch (error: any) {
                console.error('telegramBind error', error);
                useErrorStore().setError(error, 'telegram');
            } finally {
                this.loading = false;
            }
        },
        async telegramDebind() {
            this.loading = true;
            try {
                const response = await authorizedRequest(() => axios.delete('/v1/user/telegram-link', {
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().token.access}`
                    }
                }));
                if (response?.data) {
                    this.user = Object.assign(this.user, response.data);
                }
                return this.user;

            } catch (error: any) {
                console.error('telegramDebind error', error);
                useErrorStore().setError(error, 'telegram');
            } finally {
                this.loading = false;
            }
        },
        async sendFeedback() {
            this.loading = true;
            try {
                const response = await authorizedRequest(() => axios.post('/v1/feedbacks', this.body.feedback, {
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().token.access}`
                    }
                }));
                return response?.data;
                /*
                    RESPONSE DATA:
                    createDT : "2023-11-07T05:57:52.299Z"
                    id : ""
                    text : ""
                    userId : ""
                */
            } catch (error: any) {
                console.error('sendFeedback error', error);
                useErrorStore().setError(error, 'feedback');
            } finally {
                this.loading = false;
            }
        },
        /* ADDRESSES */
        async fetchAddresses() {
            if (!this.addresses.length) {
                this.loading = true;
                try {
                    const response = await authorizedRequest(() => axios.get('/v1/orders/addresses', {
                        headers: {
                            'Authorization': `Bearer ${useAuthStore().token.access}`
                        }
                    }));
                    if (response?.data?.data) {
                        this.addresses = response?.data.data
                    }
                } catch (error: any) {
                    console.error('fetchUser error', error);
                    useErrorStore().setError(error, 'address');
                } finally {
                    this.loading = false;
                }
            }
            return this.addresses;
        },
        async addAddress() {
            this.loading = true;
            try {
                const response = await authorizedRequest(() => axios.post('/v1/orders/addresses', this.body.address, {
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().token.access}`
                    }
                }));

                if (response?.data) {
                    if (this.addresses?.length === 0) {
                        const { id } = response?.data;
                        this.saveAddressToLocalStorage(id);
                    }

                    this.addresses.unshift(response.data)
                    return response.data;
                }
            } catch (error: any) {
                console.error('fetchUser error', error);
                useErrorStore().setError(error, 'address');
            } finally {
                this.loading = false;
            }
        },
        async deleteAddress(id: string) {
            this.loading = true;
            try {
                const response = await authorizedRequest(() => axios.delete(`/v1/orders/addresses/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().token.access}`
                    }
                }));

                if (response?.data) {
                    const existingAddressIndex = this.addresses.findIndex(
                        (address) => address.id === id
                    );
                    if (existingAddressIndex !== -1) {
                        this.addresses.splice(existingAddressIndex, 1);
                    }
                }

                return response?.data;
            } catch (error: any) {
                console.error('fetchUser error', error);
                useErrorStore().setError(error, 'address');
            } finally {
                this.loading = false;
            }
        },
        async loadAddressFromLocalStorage() {
            const savedAddressID = localStorage.getItem('address');
            if (savedAddressID) {
                try {
                    this.savedAddress = JSON.parse(savedAddressID);
                } catch (error) {
                    console.error('Error parsing address data:', error);
                }
            }
        },
        saveAddressToLocalStorage(addressID: string = '') {
            this.savedAddress = addressID;
            localStorage.setItem('address', JSON.stringify(addressID));
        },
        /* ORDERS */
        clearOrdersData() {
            this.clearOrderData();

            this.orders = [] as Order[];
            this.ordersPagination = {} as OrderPagination;
        },
        clearOrderData() {
            this.order = {} as OrderInfo;
        },
        clearOrderCount() {
            this.ordersCount = {} as OrderCount;
            this.totalCount = 0;
        },
        async fetchOrder(id: string) {
            this.loading = true;

            try {
                const response = await authorizedRequest(() => axios.get(`/v1/orders/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().token.access}`
                    }
                }));

                this.clearOrderData();
                if (response?.data) {
                    this.order = response.data
                    return this.order;
                }
            } catch (error: any) {
                console.error('fetchOrder error', error);
                useErrorStore().setError(error, 'order');
            } finally {
                this.loading = false;
            }
        },
        async fetchOrders(params: OrderParameters, deletePrev: boolean = true) {
            this.loading = true;

            try {
                if (deletePrev) this.clearOrdersData();
                const response = await authorizedRequest(() => axios.get('/v1/orders', {
                    params,
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().token.access}`
                    }
                }));

                if (response?.data?.data && response?.data?.pager) {
                    if (params.state) {
                        const { actionsCount } = this.ordersCount[params.state];
                        if (actionsCount) {
                            this.user.actionsCount -= actionsCount;
                            this.totalCount -= actionsCount;
                        }
                        this.ordersCount[params.state].actionsCount = 0;
                    }

                    this.orders = response.data.data
                    this.ordersPagination = response.data.pager;

                    return this.orders;
                }
            } catch (error: any) {
                console.error('fetchOrders error', error);
                useErrorStore().setError(error, 'orders');
            } finally {
                this.loading = false;
            }
        },
        async fetchOrdersCount() {
            if (!Object.keys(this.ordersCount)?.length) {
                this.loading = true;

                try {
                    const response = await authorizedRequest(() => axios.get('/v1/orders/count', {
                        headers: {
                            'Authorization': `Bearer ${useAuthStore().token.access}`
                        }
                    }));

                    if (response?.data) {
                        this.ordersCount = Object.assign(this.ordersCount, response.data);
                        this.totalCount = Object.values(this.ordersCount).reduce((sum, state) => sum + state.actionsCount, 0);

                        if (this.totalCount) {
                            this.user.actionsCount = this.totalCount;
                        }
                    }
                } catch (error: any) {
                    console.error('fetchOrdersCount error', error);
                    useErrorStore().setError(error, 'order');
                } finally {
                    this.loading = false;
                }
            }
            return this.ordersCount;
        },
        async createOrder() {
            this.loading = true;

            try {
                const response = await authorizedRequest(() => axios.post(`/v1/orders/`, this.body.order, {
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().token.access}`
                    }
                }));

                if (response?.data) {
                    const { clearCart } = useCartStore();

                    this.clearOrderCount();
                    await this.fetchOrdersCount()
                    clearCart();

                    return response?.data;
                }
            } catch (error: any) {
                console.error('createOrder error', error);
                useErrorStore().setError(error, 'order');
            } finally {
                this.loading = false;
            }
        },
        async updateOrder(id: string) {
            this.loading = true;

            try {
                const response = await authorizedRequest(() => axios.patch(`/v1/orders/${id}`, this.body.comment, {
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().token.access}`
                    }
                }));

                if (response?.data) {
                    this.order = response.data
                    return this.order;
                }
            } catch (error: any) {
                console.error('updateOrder error', error);
                useErrorStore().setError(error, 'order');
            } finally {
                this.loading = false;
            }
        },
        async cancelOrder(id: string) {
            this.loading = true;

            try {
                const response = await authorizedRequest(() => axios.put(`/v1/orders/${id}/cancel`, this.body.comment, {
                    headers: {
                        'Authorization': `Bearer ${useAuthStore().token.access}`
                    }
                }))

                if (response?.data) {
                    this.clearOrderCount();
                    await this.fetchOrdersCount()
                    this.clearOrderData();
                    this.orders = this.orders.filter(object => object.id !== id);

                    return response?.data;
                }
            } catch (error: any) {
                console.error('cancelOrder error', error);
                useErrorStore().setError(error, 'order');
            } finally {
                this.loading = false;
            }
        },
    }
});
