<template>
  <picture v-if="data" :class="{ loaded }">
    <source type="image/webp" :srcset="getSrcSet(data, 'webp')" />
    <nuxt-img
      :src="getFirstImage(data, 'jpeg')"
      :srcset="getSrcSet(data, 'jpeg')"
      :sizes="sizes"
      :width="width"
      :height="height"
      :preload="preload"
      placeholder
      loading="lazy"
      alt=""
      @load="onImageLoad"
      @error="emit('error')"
    />
  </picture>
  <div v-else class="image-empty" :style="`height: ${height}px`" />
</template>

<script lang="ts">
import type { ImageFormats } from "~/types/types";

export default defineComponent({
  props: {
    data: {
      type: Object as PropType<ImageFormats>,
      requried: true,
    },
    preload: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 0,
    },
    height: {
      type: Number,
      default: 0,
    },
  },
  emits: ["load", "error"],
  setup({ data }, { emit }) {
    const loaded = ref<boolean>(false);

    // генерируем sizes динамически на основе самого большого размера изображения
    const sizes = computed(() => {
      if (data) {
        // получаем самый большой размер изображения
        const maxWidth = Math.max(
          ...Object.keys(data).map((size) => parseInt(size.split("x")[0], 10))
        );
        return `(max-width: ${maxWidth}px) 100vw, ${maxWidth}px`;
      } else {
        return "(max-width: 340px) 100vw, 340px";
      }
    });

    // получение ссылки на первое изображение в формате для атрибута src
    const getFirstImage = (images: ImageFormats, format: "jpeg" | "webp") => {
      return images[Object.keys(images)[0]][format];
    };

    // получение srcset в зависимости от формата
    const getSrcSet = (images: ImageFormats, format: "jpeg" | "webp") => {
      return Object.entries(images)
        .map(([size, urls]) => `${urls[format]} ${size.split("x")[0]}w`)
        .join(", ");
    };

    const onImageLoad = (event: Event) => {
      loaded.value = event.isTrusted;
      emit("load");
    };

    return {
      loaded,
      sizes,

      getFirstImage,
      getSrcSet,
      onImageLoad,
    };
  },
});
</script>

<style scoped>
picture {
  display: block;
}
picture:not(.loaded) {
  display: block;
  background: #f2f7f9;
  border-radius: 11px;
}
picture:not(.loaded) > * {
  opacity: 0;
}
.image-empty {
  background: #f2f7f9;
}
</style>