<template>
  <div v-if="type === 'popup'" class="popup-search">
    <form
      action="#"
      method="post"
      class="search-form simple-form"
      @submit.prevent="handleSearch"
    >
      <div class="search-icon">
        <img width="20" height="20" src="/images/search.svg" alt="" />
      </div>
      <input
        :value="searchText"
        type="text"
        placeholder="Найти товар"
        :maxlength="255"
        @keydown="handlePrevent"
        @input="(e) => (searchText = e.target.value)"
      />
      <button
        v-if="searchText"
        type="reset"
        style="top: 2px"
        @click.prevent="handleClear"
      >
        <img width="20" height="20" src="/images/close.svg" alt="" />
      </button>
    </form>
  </div>
  <div v-else class="search-form-sticky">
    <div class="search-form-outer">
      <form class="search-form simple-form" @submit.prevent="handleSearch">
        <div class="search-icon">
          <img width="20" height="20" src="/images/search.svg" alt="" />
        </div>
        <input
          :value="searchText"
          type="text"
          placeholder="Найти товар"
          :maxlength="255"
          @keydown="handlePrevent"
          @input="(e) => (searchText = e.target.value)"
        />
        <button v-if="searchText" type="reset" @click.prevent="handleClear">
          <img width="20" height="20" src="/images/close.svg" alt="" />
        </button>
      </form>
    </div>
  </div>
</template>

<script lang="ts">
export default defineComponent({
  props: {
    type: {
      type: String,
      default: "sticky",
    },
    modelValue: {
      type: String,
      default: null,
    },
  },

  emits: ["update:modelValue", "clear"],

  setup(props, { emit }) {
    const searchText = ref<string | null>(props.modelValue);
    let searchTimeout: NodeJS.Timeout | undefined;

    const handleClear = () => {
      searchText.value = "";
      emit("clear");
    };
    const handlePrevent = (event: KeyboardEvent) => {
      // блокируем возможность юзать пробел вначале
      if (event.key === " " && !searchText.value) {
        event.preventDefault();
      }
    };

    const handleSearch = () => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      searchTimeout = setTimeout(() => {
        const text = searchText.value?.trim();
        if (text) emit("update:modelValue", text);
        else handleClear();
      }, 500);
    };

    watch(
      () => props.modelValue,
      (text: string) => {
        if (text) searchText.value = text;
        else searchText.value = "";
      }
    );
    watch(searchText, () => {
      handleSearch();
    });

    return {
      searchText,

      handleClear,
      handlePrevent,
      handleSearch,
    };
  },
});
</script>

<style scoped>
input:disabled {
  pointer-events: none;
  opacity: 0.8;
}
button:disabled {
  pointer-events: none;
  opacity: 0.8;
}
.search-form-sticky img {
  user-select: none;
}
</style>

