import { customAxios as axios } from '@/plugins/axios'
import type { Category, ProductParameters, ProductResponse, Product, ProductInfo, Pagination } from '~/types/types';

import { useErrorStore } from './error';
import { useCityStore } from './cities';
import { usePartnerStore } from './partners';

export const useStore = defineStore('store', {
    state: () => {
        return {
            loading: false,
            productLoading: false,
            category: {} as Category,
            categories: [] as Category[],
            default: {
                page: 1,
                pageSize: 20,
                categoryId: null,
                categorySlug: null,
                partnerId: null,
                search: null,
                sort: null,
            } as ProductParameters,
            params: {} as ProductParameters,
            product: {} as ProductInfo,
            productLink: '' as string,
            products: [] as Product[],
            pagination: {} as Pagination
        };
    },
    getters: {
        partnerId: () => {
            const store = usePartnerStore();
            const { current } = storeToRefs(store);

            return current.value?.id || null;
        },
        cityId: (): string | null => {
            const store = useCityStore();
            const { city } = storeToRefs(store);

            return city.value?.id || null;
        },
    },
    actions: {
        clearCategories() {
            this.categories = [];
        },
        clearParams() {
            this.params = JSON.parse(JSON.stringify(this.default));
        },
        replaceParams(data: Partial<ProductParameters>) {
            Object.assign(this.params, this.default, data);
        },
        updateParams(data: Partial<ProductParameters>) {
            Object.assign(this.params, data);
        },
        async fetchCategories() {
            if (this.categories.length === 0) {
                this.loading = true;

                let params = {};

                const savedCity = localStorage.getItem('city');
                if (savedCity) {
                    const parsedCity = JSON.parse(savedCity)

                    try {
                        params = { cityId: parsedCity?.id || this.cityId || null, partnerId : this.partnerId }
                    } catch (error) {
                        console.error('Error parsing city data:', error);
                    }
                } else {
                    params = { cityId: this.cityId || null }
                }

                try {
                    const response: { data: { data: Category[] } } = await axios.get('/v1/categories', { params });
                    const { data } = response.data;

                    this.categories = data;
                } catch (error: any) {
                    useErrorStore().setError(error, 'category');
                    console.error('fetchCategories error', error)
                } finally {
                    this.loading = false;
                }
            }

            return this.categories;
        },
        async fetchCategoryByArg(slug: string) {
            this.loading = true;

            try {
                const response: { data: Category } = await axios.get(`/v1/categories/${slug}`);
                this.category = response.data;
            } catch (e) {
                console.error('fetchCategoryByArg error', e)
            } finally {
                this.loading = false;
            }

            return this.category;
        },
        findCategoryBySlug(
            categories: Category[],
            slug: string
        ): Category | null {
            for (let category of categories) {
                if (category.slug === slug) {
                    return category;
                }
                if (category.children) {
                    let found: Category | null = this.findCategoryBySlug(category.children, slug);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        },
        async fetchProducts() {
            this.loading = true;

            try {
                const params = { ...this.params, cityId: this.cityId, partnerId : this.partnerId };
                const { data }: { data: ProductResponse } = await axios.get('/v1/products', { params });

                if ((params.categoryId || params.categorySlug || params.cityId || params.partnerId || params.search || params.sort) && params.page == 1) {
                    this.products = data.data;
                } else {
                    this.products.push(...data.data);
                }

                this.pagination = data.pager;
            } catch (error: any) {
                useErrorStore().setError(error, 'product');
                console.error('fetchProducts error', error)
            } finally {
                this.loading = false;
            }

            return this.products;
        },
        async fetchProductByArg(arg: string) {
            this.productLoading = true;

            try {
                const { data }: { data: ProductInfo } = await axios.get(`/v1/products/${arg}`);
                this.product = data;
            } catch (error: any) {
                useErrorStore().setError(error, 'product');
                console.error('fetchProductByArg error', error)
            } finally {
                this.productLoading = false;
            }

            return this.product;
        },
    }
});
