export function usePriceFormat() {
    const priceFormat = (price: string | number) => {
        const p = Number(price) || 0;
        return p.toLocaleString("ru-RU", {
            style: "currency",
            currency: "RUB",
            minimumFractionDigits: 0,
        });
    };

    return {
        priceFormat
    }
}