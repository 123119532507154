import axios from 'axios'
import type { AxiosInstance } from 'axios';

const instance: AxiosInstance = axios.create({
    baseURL: (import.meta.env.VITE_API_ENDPOINT || process.env.API_ENDPOINT || 'http://178.250.159.149:3001/'),
})

export default defineNuxtPlugin((nuxt) => {
    instance.interceptors.request.use(async config => {
        config.headers['Content-Type'] = 'application/json';
        config.headers['Accept-Language'] = 'ru';

        if (process.server) {
            const nodeVersion = process.versions.node;
            const customUserAgent = `MyApp/1.0 (Node.js ${nodeVersion})`;
            config.headers['User-Agent'] = customUserAgent;
        }

        return config;
    });

    nuxt.provide('axios', instance);
});

export { instance as customAxios }
