
// Generated by dayjs-nuxt-module
import dayjs from 'dayjs'
import 'dayjs/locale/ru'
import duration from 'dayjs/plugin/duration'
import updateLocale from 'dayjs/plugin/updateLocale'
import utc from 'dayjs/plugin/utc'

dayjs.extend(duration)
dayjs.extend(updateLocale)
dayjs.extend(utc)
dayjs.extend(updateLocale)
dayjs.locale('ru')



dayjs.updateLocale("ru")

export default dayjs
