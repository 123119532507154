<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="#FF7043" :throttle="250" />
    <NuxtPage />
  </NuxtLayout>
</template>

<script lang="ts" setup>
const { metaData } = useSeoTags();
useHead(metaData());
</script>