import { useAuthStore } from '~/store/auth';

export async function authorizedRequest<T>(request: () => Promise<T>, retryCount = 3): Promise<T | null> {
    const authStore = useAuthStore();

    // проверка на наличие токенов перед выполнением запроса
    if (!authStore.token.access || !authStore.token.refresh) {
        // console.warn('No access or refresh token available.');
        return null;
    }

    try {
        return await request();
    } catch (error: any) {
        if ((error?.response?.status === 401 || error?.response?.status === 403) && retryCount > 0) {
            if (!authStore.isRefreshingTokens) {
                await authStore.refreshTokens();
            } else {
                await new Promise(resolve => setTimeout(resolve, 500));
            }
            return authorizedRequest(request, retryCount - 1);
        } else {
            throw error;
        }
    }

}