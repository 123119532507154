import { default as _91slug_93jhkIYWSfxnMeta } from "/root/projects/penguin-frontend/pages/[category]/[slug].vue?macro=true";
import { default as indexWaWdUFdmOcMeta } from "/root/projects/penguin-frontend/pages/[category]/index.vue?macro=true";
import { default as indexdKwtzZaM2tMeta } from "/root/projects/penguin-frontend/pages/index.vue?macro=true";
export default [
  {
    name: _91slug_93jhkIYWSfxnMeta?.name ?? "category-slug",
    path: _91slug_93jhkIYWSfxnMeta?.path ?? "/:category()/:slug()",
    meta: _91slug_93jhkIYWSfxnMeta || {},
    alias: _91slug_93jhkIYWSfxnMeta?.alias || [],
    redirect: _91slug_93jhkIYWSfxnMeta?.redirect || undefined,
    component: () => import("/root/projects/penguin-frontend/pages/[category]/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexWaWdUFdmOcMeta?.name ?? "category",
    path: indexWaWdUFdmOcMeta?.path ?? "/:category()",
    meta: indexWaWdUFdmOcMeta || {},
    alias: indexWaWdUFdmOcMeta?.alias || [],
    redirect: indexWaWdUFdmOcMeta?.redirect || undefined,
    component: () => import("/root/projects/penguin-frontend/pages/[category]/index.vue").then(m => m.default || m)
  },
  {
    name: indexdKwtzZaM2tMeta?.name ?? "index",
    path: indexdKwtzZaM2tMeta?.path ?? "/",
    meta: indexdKwtzZaM2tMeta || {},
    alias: indexdKwtzZaM2tMeta?.alias || [],
    redirect: indexdKwtzZaM2tMeta?.redirect || undefined,
    component: () => import("/root/projects/penguin-frontend/pages/index.vue").then(m => m.default || m)
  }
]