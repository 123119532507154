<template>
    <div class="sellers-list">
        <ul>
            <li v-if="currentPartner?.id">
                <a :href="`?partner=${currentPartner.slug}`" class="active">
                    {{ currentPartner.title || currentPartner.slug }}
                    <img v-if="checkCartAvail(currentPartner)" width="14" height="14" src="/images/cart.svg" alt="" />
                </a>
            </li>
            <template v-for="partner in partners" :key="partner.id">
                <li v-if="partner.id !== currentPartner.id">
                    <a :href="`?partner=${partner.slug}`" @click.prevent="setPartner(partner)">
                        {{ partner.title || partner.slug }}
                        <img v-if="checkCartAvail(partner)" width="14" height="14" src="/images/cart-default.svg"
                            alt="" />
                    </a>
                </li>
            </template>
        </ul>
    </div>
</template>

<script setup lang="ts">
import { useCartStore } from "~/store/cart";
import { usePartnerStore } from "~/store/partners";

import type { Partner } from "~/types/types";

const partnerStore = usePartnerStore()
const cartStore = useCartStore();

const { setPartner } = partnerStore;
const { current: currentPartner, partners } = storeToRefs(partnerStore);
const { cart } = storeToRefs(cartStore);

const availInCart = computed(() => {
    return !!cart.value.products?.length;
});
const checkCartAvail = (partner: Partner) => {
    const partnerId = partner.id;
    if (availInCart.value) {
        return cart.value.products.some(product => product?.partner?.id === partnerId);
    }

    return availInCart.value;
}
</script>

<style lang="less" scoped>
.sellers-list {
    position: sticky;
    top: 185px;
    background-color: #fff;
    margin: 0;
    padding: 0 20px 20px;
    z-index: 4;
    
    @media (max-width: 1019px) {
        top: 0;
    }
}
</style>