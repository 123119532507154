import revive_payload_client_4sVQNw7RlN from "/root/projects/penguin-frontend/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/root/projects/penguin-frontend/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/root/projects/penguin-frontend/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "/root/projects/penguin-frontend/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_A0OWXRrUgq from "/root/projects/penguin-frontend/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/root/projects/penguin-frontend/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/root/projects/penguin-frontend/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_8SbxDRbG6Y from "/root/projects/penguin-frontend/node_modules/dayjs-nuxt/dist/runtime/plugin.mjs";
import chunk_reload_client_UciE0i6zes from "/root/projects/penguin-frontend/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/root/projects/penguin-frontend/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import axios_QMFgzss1s4 from "/root/projects/penguin-frontend/plugins/axios.ts";
import maska_UHaKf2z1iQ from "/root/projects/penguin-frontend/plugins/maska.ts";
import scroll_up_hpw1K3462r from "/root/projects/penguin-frontend/plugins/scroll-up.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  plugin_8SbxDRbG6Y,
  chunk_reload_client_UciE0i6zes,
  check_outdated_build_client_8vK7RkfGxZ,
  axios_QMFgzss1s4,
  maska_UHaKf2z1iQ,
  scroll_up_hpw1K3462r
]