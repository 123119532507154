import { customAxios as axios } from '@/plugins/axios';
import { useErrorStore } from './error';

import { useAuthStore } from './auth';
import { useProfileStore } from './profile';

import type { Category, Image, Order, OrderBody, OrderCount, OrderInfo, OrderParameters, Pagination, ProductBody, ProductCart, ProductInfo, ProductParameters, User, UserPermission } from "~/types/types";

export const useAdminStore = defineStore('admin', {
    state: () => {
        return {
            loading: false,
            updateStatus: false,
            /* PRODUCTS */
            categories: [] as Category[],
            product: {} as ProductInfo,
            products: [] as ProductInfo[],
            edit: {
                category: {} as Category,
                product: {} as ProductInfo
            },
            /* ORDERS */
            order: {} as OrderInfo,
            orders: [] as Order[],
            ordersCount: {} as OrderCount,
            totalCount: 0,
            /* MAIN (PARAMS) */
            body: {
                product: {} as ProductBody,
                order: {} as OrderBody
            },
            params: {
                products: {
                    page: 1,
                    pageSize: 10,
                    categoryId: null,
                    search: null,
                },
                orders: {
                    page: 1,
                    pageSize: 10,
                    state: 30,
                    search: null,
                }
            },
            default: {
                products: {
                    page: 1,
                    pageSize: 10,
                    categoryId: null,
                    search: null,
                },
                orders: {
                    page: 1,
                    pageSize: 10,
                    state: 30,
                    search: null,
                }
            },
            pagination: {
                products: {} as Pagination,
                orders: {} as Pagination
            }
        };
    },
    getters: {
        token: (): string | null => {
            const store = useAuthStore();
            const { token } = storeToRefs(store);

            return token.value.access;
        },
        user: (): User => {
            const store = useProfileStore();
            const { user } = storeToRefs(store);

            return user.value;
        },
        isAdmin: (): boolean => {
            const store = useProfileStore();
            const { user } = storeToRefs(store);

            return user.value.type === 100 || user.value.type === 50;
        }
    },
    actions: {
        clearAllAdminState() {
            this.clearBody()
            this.clearParams()
            this.clearProductsData()
            this.clearOrdersData()
            this.clearOrderData()
            this.clearOrderCount()
        },
        clearBody() {
            this.body = {
                product: {} as ProductBody,
                order: {} as OrderBody
            }
        },
        clearParams() {
            this.params = JSON.parse(JSON.stringify(this.default));
        },
        clearProductsData() {
            this.clearParams();

            this.product = {} as ProductInfo;
            this.products = [] as ProductInfo[];
            this.pagination.products = {} as Pagination;
        },
        clearProducts() {
            this.products = [] as ProductInfo[];
            this.pagination.products = {} as Pagination;
        },
        clearOrdersData() {
            this.clearParams();

            this.orders = [] as Order[];
            this.pagination.orders = {} as Pagination;
        },
        clearOrderData() {
            this.order = {} as OrderInfo;
        },
        clearOrderCount() {
            this.ordersCount = {} as OrderCount;
            this.totalCount = 0;
        },
        updateBody(data: Partial<ProductBody | OrderBody>, type: keyof typeof this.body) {
            Object.assign(this.body[type], data);
        },
        replaceParams(data: Partial<ProductParameters>, type: keyof typeof this.params = 'products') {
            Object.assign(this.params[type], this.default, data);
        },
        updateParams(data: Partial<ProductParameters>, type: keyof typeof this.params = 'products') {
            Object.assign(this.params[type], data);
        },
        hasPermission(permission: UserPermission) {
            if (this.user) {
                return this.user.type === 100 || this.user.type === 50 && this.user?.permissions.includes(permission);
            } else {
                return false
            }
        },
        /* PRODUCTS */
        async fetchAdminCategories() {
            if (this.categories.length === 0) {
                this.loading = true;
                try {
                    const params = { onlyCommon: true }
                    const response: { data: Category[] } | null = await authorizedRequest(
                        () => axios.get('/api/categories', {
                            params,
                            headers: {
                                'Authorization': `Bearer ${this.token}`
                            }
                        }), 1);

                    if (response) {
                        const { data } = response;
                        this.categories = data;
                    } else {
                        this.categories = [];
                    }
                } catch (error: any) {
                    useErrorStore().setError(error, 'admin');
                    console.error('fetchAdminCategories error', error)
                } finally {
                    this.loading = false;
                }
            }

            return this.categories;
        },
        async fetchAdminProducts(replacePrev: boolean = false) {
            if (replacePrev) {
                this.products = [] as ProductInfo[];
                this.pagination.products = {} as Pagination;
            }

            this.loading = true;
            try {
                const params = { ...this.params.products };
                const response: { data: { data: ProductInfo[], pager: Pagination } } | null = await authorizedRequest(
                    () => axios.get(`/api/products/`, {
                        params,
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    }), 1);

                if (response) {
                    const { data, pager } = response.data;
                    if (replacePrev) {
                        this.products = data;
                    } else {
                        this.products = this.products.concat(data);
                    }

                    this.pagination.products = pager;
                }
                else this.clearProductsData();

            } catch (error: any) {
                useErrorStore().setError(error, 'admin');
                console.error('fetchAdminProducts error', error)
            } finally {
                this.loading = false;
            }

            return this.edit.category;
        },
        async updateProduct(productId: string) {
            this.updateStatus = false;

            try {
                const response: { data: ProductInfo } | null = await authorizedRequest(() => axios.patch(`/api/products/${productId}`, this.body.product, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                }), 1);

                if (response?.data) {
                    this.clearBody();
                    this.edit.product = {} as ProductInfo

                    this.updateStatus = true;

                    return response.data;
                }
            } catch (error: any) {
                console.error('updateProduct error', error);
                useErrorStore().setError(error, 'admin');
            }
        },
        /* ORDERS */
        async fetchAdminOrders(params: OrderParameters, replacePrev: boolean = false) {
            if (replacePrev) {
                this.orders = [] as Order[];
                this.pagination.orders = {} as Pagination;
            }

            this.loading = true;
            try {
                const response: { data: { data: Order[], pager: Pagination } } | null = await authorizedRequest(
                    () => axios.get('/api/orders', {
                        params,
                        headers: {
                            'Authorization': `Bearer ${this.token}`
                        }
                    }), 1);

                if (response) {
                    const { data, pager } = response.data;
                    if (replacePrev) {
                        this.orders = data;
                    } else {
                        this.orders = this.orders.concat(data);
                    }
                    this.pagination.orders = pager;
                } else {
                    this.orders = [];
                }
            } catch (error: any) {
                useErrorStore().setError(error, 'admin');
                console.error('fetchAdminOrders error', error)
            } finally {
                this.loading = false;
            }

            return this.orders;
        },
        async fetchAdminOrdersCount() {
            // if (!Object.keys(this.ordersCount)?.length) {
            this.loading = true;

            try {
                const response = await authorizedRequest(() => axios.get('/api/orders/count', {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                }), 1);

                if (response?.data) {
                    this.ordersCount = Object.assign(this.ordersCount, response.data);
                    this.totalCount = Object.values(this.ordersCount).reduce((sum, state) => sum + state.newCount, 0);
                }
            } catch (error: any) {
                console.error('fetchAdminOrdersCount error', error);
                useErrorStore().setError(error, 'admin');
            } finally {
                this.loading = false;
            }
            // }

            return this.ordersCount;
        },
        async fetchAdminOrder(id: string) {
            this.loading = true;

            try {
                const response: { data: OrderInfo } | null = await authorizedRequest(() => axios.get(`/api/orders/${id}`, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                }), 1);

                if (response?.data) {
                    this.clearOrderData();

                    this.order = response.data
                    return this.order;
                }
            } catch (error: any) {
                console.error('fetchAdminOrder error', error);
                useErrorStore().setError(error, 'admin');
            } finally {
                this.loading = false;
            }
        },
        async updateOrderProduct(orderId: string, productId: string) {
            this.loading = true;

            try {
                const response: { data: { orderTotalCost: number, productCost: number } } | null = await authorizedRequest(() => axios.put(`/api/orders/${orderId}/${productId}`, this.body.order, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                }))

                if (response?.data) {
                    const { orderTotalCost, productCost } = response.data;

                    const index = this.order.products.findIndex((product) => product.id === productId);
                    if (index !== -1 && this.order.products?.length) {
                        let product = this.order.products[index] as ProductCart;
                        product.cost = productCost;
                        product.count = this.body.order?.count || 1;

                        this.order.products[index] = product;
                    }
                    this.order.totalCost = orderTotalCost;

                    return response.data;
                }
            } catch (error: any) {
                console.error('updateOrderProduct error', error);
                useErrorStore().setError(error, 'admin');
            } finally {
                this.loading = false;
            }
        },
        async removeOrderProduct(orderId: string, productId: string) {
            this.loading = true;

            try {
                const response: { data: { orderTotalCost: number } } | null = await authorizedRequest(() => axios.delete(`/api/orders/${orderId}/${productId}`, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                }))

                if (response?.data) {
                    const indexToDelete = this.order.products.findIndex((product) => product.id === productId);
                    if (indexToDelete !== -1 && this.order.products?.length) {
                        this.order.products.splice(indexToDelete, 1);
                    }

                    const { orderTotalCost } = response.data;
                    this.order.totalCost = orderTotalCost;

                    return response.data;
                }
            } catch (error: any) {
                console.error('removeOrderProduct error', error);
                useErrorStore().setError(error, 'admin');
            } finally {
                this.loading = false;
            }
        },
        async updateOrderState(id: string) {
            this.loading = true;

            try {
                const response = await authorizedRequest(() => axios.put(`/api/orders/${id}/state`, this.body.order, {
                    headers: {
                        'Authorization': `Bearer ${this.token}`
                    }
                }))

                if (response?.data) {
                    this.clearOrderCount();
                    await this.fetchAdminOrdersCount()
                    this.clearOrderData();
                    this.orders = this.orders.filter(object => object.id !== id);

                    return response?.data;
                }
            } catch (error: any) {
                console.error('updateOrderState error', error);
                useErrorStore().setError(error, 'admin');
            } finally {
                this.loading = false;
            }
        },
        async fetchProductImagesById(id: string) {
            this.loading = true;

            try {
                const response: { data: { data: Image[] } } = await axios.get(`/api/products/${id}/images`);

                if (response.data) {
                    const { data } = response.data;
                    this.product.imagesWithoutFormat = data;
                }
            } catch (error: any) {
                useErrorStore().setError(error, 'admin');
                console.error('fetchProductImagesById error', error)
            } finally {
                this.loading = false;
            }

            return this.product.images;
        },
    }
});
