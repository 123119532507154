<template>
  <NuxtLayout>
    <NuxtLoadingIndicator color="#FF7043" :throttle="250" />
    <ProductsCatalog />
  </NuxtLayout>
</template>

<script lang="ts" setup>
import { useModalStore } from "~/store/modal";

defineProps(["error"]);

const { openModal } = useModalStore();
openModal("error");
</script>