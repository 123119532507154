import { customAxios as axios } from '@/plugins/axios'
import { useStore } from './index'

import { useModalStore } from './modal';
import { usePartnerStore } from './partners';

import type { Cart, CartBody, CartLimits, Partner, ProductCart } from '~/types/types';

// Функция для определения партнера с наибольшим количеством товаров в корзине
function getTopPartner(cart: ProductCart[]): Partner | null {
    if (cart.length === 0) {
        return null; // Корзина пуста
    }

    const partnerCount: { [key: string]: number } = {};

    // Подсчет количества товаров от каждого партнера
    for (const product of cart) {
        const partnerId = product.partner?.id;
        if (partnerCount[partnerId]) {
            partnerCount[partnerId]++;
        } else {
            partnerCount[partnerId] = 1;
        }
    }

    // Определение партнера с максимальным количеством товаров
    let topPartnerId = '';
    let maxCount = 0;

    for (const partnerId in partnerCount) {
        if (partnerCount[partnerId] > maxCount) {
            maxCount = partnerCount[partnerId];
            topPartnerId = partnerId;
        }
    }

    // Найти и вернуть объект партнера с максимальным количеством товаров
    return cart.find(product => product.partner?.id === topPartnerId)?.partner || null;
}
/*
// Функция для определения партнера с наименьшим количеством товаров в корзине
function getMinPartner(cart: ProductCart[]): Partner | null {
    if (cart.length === 0) {
      return null; // Корзина пуста
    }
  
    const partnerCount: { [key: string]: number } = {};
  
    // Подсчет количества товаров от каждого партнера
    for (const product of cart) {
      const partnerId = product.partner.id;
      if (partnerCount[partnerId]) {
        partnerCount[partnerId]++;
      } else {
        partnerCount[partnerId] = 1;
      }
    }
  
    // Определение партнера с минимальным количеством товаров
    let minPartnerId = '';
    let minCount = Infinity;
  
    for (const partnerId in partnerCount) {
      if (partnerCount[partnerId] < minCount) {
        minCount = partnerCount[partnerId];
        minPartnerId = partnerId;
      }
    }
  
    // Найти и вернуть объект партнера с минимальным количеством товаров
    return cart.find(product => product.partner.id === minPartnerId)?.partner || null;
}
*/

export const useCartStore = defineStore('cart', {
    state: () => {
        return {
            loading: false,
            cashedProduct: {} as ProductCart,
            cart: {
                products: [],
                totalCost: 0,
                syncDT: null
            } as Cart,
            limits: {
                minOrderTotalCost: 0,
                maxProductCount: 100,
                maxProductsLength: 50
            } as CartLimits,
            missingProducts: [] as ProductCart[]
        };
    },
    getters: {
        // возвращает массив продуктов с полями для запросов
        compactedProducts: (state): { id: string; count: number }[] => {
            return state.cart.products.map(product => ({
                id: product.id,
                count: product.count,
            }));
        },
        cartPartner: (state): Partner | null => {
            const topPartner = getTopPartner(state.cart.products);
            return topPartner;
        },
        hasMultiplePartners(state): boolean {
            const partnerIds = new Set<string>();
          
            for (const product of state.cart.products) {
              partnerIds.add(product.partner.id);
            }

            return partnerIds.size > 1;
        }
    },
    actions: {
        clearCart() {
            this.cart.products = []
            this.cart.totalCost = 0
            this.cart.syncDT = null
            this.missingProducts = []

            this.removeCartToLocalStorage()
        },
        async addToCart(product: ProductCart, quantity?: number) {
            if (this.isProductFromNewPartner(product)) {
                const modalStore = useModalStore()
                modalStore.openModal('partnerWarn')
                this.cashedProduct = product;

                return;
            }

            if (this.cart.products?.length === 0) {
                const partnersStore = usePartnerStore()
                partnersStore.setPartner(product.partner)
            }

            this.loading = true;
            try {
                const { data }: { data: { quantityStock: number } } = await axios.get(`/v1/products/${product.id}/quantity-stock`);
                const { quantityStock } = data;

                const existingProduct = this.cart.products.find((p) => p.id === product.id);
                if (existingProduct) {
                    if (typeof quantityStock === 'number') existingProduct.quantityStock = quantityStock
                    const max = existingProduct.quantityStock >= this.limits.maxProductCount ? this.limits.maxProductCount : existingProduct.quantityStock;

                    if (quantity !== undefined) {
                        existingProduct.count = Math.min(quantity, max);
                    } else {
                        if (existingProduct.count >= max) {
                            existingProduct.count = max
                        } else if (existingProduct.count >= existingProduct.quantityStock) {
                            existingProduct.count = existingProduct.quantityStock
                        } else existingProduct.count += 1;
                    }
                    existingProduct.cost = (existingProduct.priceDiscount ?? existingProduct.price) * existingProduct.count;

                    let q = quantity || 0;
                    if ((existingProduct.count > quantityStock) || (q > quantityStock)) {
                        this.missingProducts.push(existingProduct);
                    }
                } else {
                    const newProduct = {
                        ...product,
                        count: quantity !== undefined ? Math.min(quantity, this.limits.maxProductCount) : 1,
                        cost: (product.priceDiscount ?? product.price) * (quantity !== undefined ? quantity : 1),
                        isSkiped: quantityStock > 0 ? false : true,
                        quantityStock
                    };

                    if (quantityStock > 0) {
                        this.cart.products.push(newProduct);
                    } else {
                        this.missingProducts.push(newProduct);
                    }
                }

                this.cart.totalCost = this.cart.products
                    .filter(product => !product.isSkiped)
                    .reduce((total, product) => total + (product.priceDiscount ?? product.price) * product.count, 0);

                this.saveCartToLocalStorage();
            } catch (e) {
                console.error('addToCart error', e)
            } finally {
                this.loading = false;
            }
        },
        async removeFromCart(productId: string, removeCompletely: boolean = false) {
            this.loading = true;

            try {
                const existingProductIndex = this.cart.products.findIndex(
                    (product) => product.id === productId
                );
                if (existingProductIndex !== -1) {
                    const existingProduct = this.cart.products[existingProductIndex];

                    if (removeCompletely || existingProduct.count === 1) {
                        this.cart.products.splice(existingProductIndex, 1);

                        if (!existingProduct.isSkiped) {
                            this.cart.totalCost -= (existingProduct.priceDiscount ?? existingProduct.price) * (existingProduct.count ?? 1);
                        }
                    } else {
                        const { data }: { data: { quantityStock: number } } = await axios.get(`/v1/products/${existingProduct.id}/quantity-stock`);

                        if (typeof data.quantityStock === 'number') existingProduct.quantityStock = data.quantityStock;
                        existingProduct.count -= 1;

                        if (!existingProduct.isSkiped) {
                            this.cart.totalCost -= existingProduct.price;
                        }
                        existingProduct.cost = (existingProduct.priceDiscount ?? existingProduct.price) * existingProduct.count;
                    }

                    if (!removeCompletely && existingProduct.count > existingProduct.quantityStock) {
                        this.missingProducts.push(existingProduct);
                    }

                    this.saveCartToLocalStorage();
                }
            } catch (e) {
                console.error('removeFromCart error', e)
            } finally {
                this.loading = false;
            }
        },
        updateCartProducts() {
            const updatedCart: ProductCart[] = this.cart.products
                .map((cartItem) => {
                    const missProduct = this.missingProducts.find((item) => item.id === cartItem.id);
                    if (missProduct) {
                        const { price, quantityStock } = missProduct;
                        if (quantityStock === 0) {
                            return null;
                        }
                        const count = quantityStock >= this.limits.maxProductCount ? this.limits.maxProductCount : quantityStock;
                        return {
                            ...cartItem,
                            cost: price * count,
                            count,
                            isSkiped: false
                        };
                    }
                    return cartItem;
                })
                .filter((cartItem) => cartItem !== null) as ProductCart[];

            this.cart.products = updatedCart;
            this.cart.totalCost = this.cart.products
                .reduce((total, product) => total + ((product.priceDiscount ?? product.price) * (product.count ?? 1)), 0);

            this.missingProducts = [];
        },
        // actions localStorage
        saveCartToLocalStorage() {
            localStorage.setItem('cart', JSON.stringify(this.cart));
        },
        removeCartToLocalStorage() {
            localStorage.removeItem('cart');
        },
        async loadCartFromLocalStorage() {
            const savedCart = localStorage.getItem('cart');
            if (savedCart) {
                try {
                    this.cart = JSON.parse(savedCart);
                } catch (error) {
                    console.error('Error parsing cart data:', error);
                }
            }
        },
        // недостающие продукты
        setMissingProducts(products: ProductCart[]) {
            if (products?.length) this.missingProducts = products;
            else this.missingProducts = []
        },
        // лимиты корзины (макс кол-во продуктов и.т.д)
        async fetchCartLimits() {
            if (this.cart.products?.length && !this.limits.fetched) {
                this.loading = true;

                try {
                    const { data }: { data: CartLimits } = await axios.get('/v1/cart/limits', {
                        params: {
                            partnerId: this.cartPartner?.id
                        }
                    });
                    this.limits = data;
                    this.limits.fetched = true;
                } catch (e) {
                    console.error('fetchCartLimits error', e)
                    this.limits.fetched = false;
                } finally {
                    this.loading = false;
                }
            }

            return this.limits;
        },
        // при инициализации страницы, если корзина не пустая - то актуализируем данные
        async syncCart() {
            if (this.cart.products?.length) {
                this.loading = true;
                const body: CartBody = { products: this.compactedProducts }

                try {
                    const { data }: { data: Cart } = await axios.post('/v1/cart/template', body);
                    const missingProducts = data.products.filter((product) => product.count > product.quantityStock);
                    this.setMissingProducts(missingProducts)

                    data.products = data.products
                        .filter((product) => product.quantityStock > 0)
                        .map((product) => {
                            if (product.count > product.quantityStock) {
                                product.count = product.quantityStock;
                            }
                            return product;
                        });
                    this.cart = data;

                    this.saveCartToLocalStorage();
                } catch (e) {
                    console.error('synchCart error', e)
                } finally {
                    this.loading = false;
                }
            }

            return this.cart;
        },
        async syncCartProducts() {
            const store = useStore();
            const { cityId } = storeToRefs(store);

            let filteredProducts: ProductCart[] = this.cart.products;
            if (cityId.value) {
                filteredProducts = this.cart.products.filter(product => {
                    return product.partner?.cities?.some(id => [cityId.value].includes(id));
                });
            }

            try {
                this.cart.products = filteredProducts?.length ? filteredProducts : [];
                this.cart.totalCost = this.cart.products
                    .reduce((total, product) => total + ((product.priceDiscount ?? product.price) * (product.count ?? 1)), 0);

                this.saveCartToLocalStorage();

                await this.syncCart();
            }
            catch (e) {
                console.error('syncCartProducts error', e)
            }
        },
        isProductFromNewPartner(newProduct: ProductCart): boolean {
            if (this.cart.products.length) {
                return !this.cart.products.some(product => product.partner?.id === newProduct.partner?.id);
            } else {
                return false;
            }
        }
    }
});
