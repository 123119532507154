<template>
  <div class="loader-backdrop" :class="className" />
  <div class="loader-container" :class="className">
    <span class="loader" />
  </div>
</template>

<script lang="ts">
import { useModalStore } from "~/store/modal";

export default defineComponent({
  props: {
    type: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const store = useModalStore();

    const isModal = computed(() => {
      return Object.values(store.modal).includes(true);
    });
    const className = props.type ? props.type : isModal.value ? "modal" : "";

    return {
      className,
    };
  },
});
</script>

<style lang="less" scoped>
.loader {
  width: 2.6rem;
  height: 2.6rem;
  border: 5px solid #000;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  pointer-events: none;
  user-select: none;

  @media (max-width: 576.98px) {
    width: 2rem;
    height: 2rem;
    border-width: 4px;
  }

  &-backdrop {
    cursor: wait;
    position: absolute;
    backdrop-filter: blur(2px);
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    opacity: 1;
    z-index: 5;

    @media screen and (max-width: 1019.98px) {
      backdrop-filter: blur(1px);
    }
    @media screen and (max-width: 767.98px) {
      height: 150%;
      top: -50%;
    }

    &:before {
      content: "";
      background: rgba(#fff, 0.6);
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
    }
  }

  &-container {
    cursor: wait;
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%);
    z-index: 10;
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* TYPES */
.product .loader,
.cart .loader,
.modal .loader {
  width: 2rem;
  height: 2rem;
  border-width: 4px;
}

.loader {
  &-backdrop {
    &.cart,
    &.product,
    &.modal {
      height: 100%;
      position: absolute;
      top: 0;
    }
  }
  &-container {
    &.cart {
      top: 45%;
      position: absolute;
    }
    &.modal {
      top: 50%;
      // top: 42.5%;
    }
    &.product {
      top: 42.5%;
    }
    @media screen and (min-width: 1020px) {
      &:not(.cart, .product, .modal) {
        margin: 20px -20px;
      }
    }
  }
}
</style>